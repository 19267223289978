import * as THREE from 'three'

export function DrawSimpleTriangle(scene)
{
    const geometry = new THREE.BufferGeometry();

    const positions = new Float32Array([
        0, 0, 0,
        0, 1, 0,
        1, 0, 0
    ])

    const positionsAttribute = new THREE.BufferAttribute(positions, 3)
    geometry.setAttribute('position', positionsAttribute)
    const material = new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true })
    const mesh = new THREE.Mesh(geometry, material)
    scene.add(mesh)
}