import * as THREE from 'three'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'

export default class MaterialPlain
{
    constructor (scene)
    {

        //Load in the environment map
        this.rgbeLoader = new RGBELoader()
        this.rgbeLoader.load('Textures/Environments/2k.hdr', (environmentMap) =>
        {
            environmentMap.mapping = THREE.EquirectangularReflectionMapping

            scene.background = environmentMap
            scene.environment = environmentMap
        })

        //--------------------
        // Sphere
        //--------------------
        this.sphereMaterial = new THREE.MeshStandardMaterial()
        this.sphereMaterial.metalness = 1
        this.sphereMaterial.roughness = 0
        this.sphere = new THREE.Mesh(
            new THREE.SphereGeometry(0.5),
            this.sphereMaterial
        )
        this.sphere.position.x = -1.5

        scene.add(this.sphere)

        //---------------------
        // Door
        //---------------------
        //Create a loading manager so we can catch errors
        this.loadingManager = new THREE.LoadingManager()
        this.loadingManager.onError = () =>
        {
            console.log('loading error')
        }

        //Load the texture
        this.textureLoader = new THREE.TextureLoader(this.loadingManager)
        this.textureColor = this.textureLoader.load("Textures/Door/Door_Wood_001_basecolor.jpg")
        this.textureColor.colorSpace = THREE.SRGBColorSpace
        this.textureAO = this.textureLoader.load("Textures/Door/Door_Wood_001_ambientOcclusion.jpg")
        this.textureHeight = this.textureLoader.load("Textures/Door/Door_Wood_001_height.png")
        this.textureMetallic = this.textureLoader.load("Textures/Door/Door_Wood_001_metallic.jpg")
        this.textureNormal = this.textureLoader.load("Textures/Door/Door_Wood_001_normal.jpg")
        this.textureOpacity = this.textureLoader.load("Textures/Door/Door_Wood_001_opacity.jpg")
        this.textureRoughness = this.textureLoader.load("Textures/Door/Door_Wood_001_roughness.jpg")

        //Create the plain and add it to the scene
        this.material = new THREE.MeshStandardMaterial({ map: this.textureColor })
        this.material.side = THREE.DoubleSide

        this.material.aoMap = this.textureAO
        this.material.aoMapIntensity = 1

        this.material.displacementMap = this.textureHeight
        this.material.displacementScale = 0.1

        this.material.metalnessMap = this.textureMetallic
        this.material.roughnessMap = this.textureRoughness

        this.material.normalMap = this.textureNormal
        this.material.normalScale.set(1, 1)

        this.material.transparent = true
        this.material.alphaMap = this.textureOpacity

        this.plain = new THREE.Mesh(
            new THREE.PlaneGeometry(3,3,100,100),
            this.material
        )
        scene.add(this.plain)

        //---------------------
        // Torus
        //--------------------
        this.torusMaterial = new THREE.MeshPhysicalMaterial()
        this.torus = new THREE.Mesh(
            new THREE.TorusGeometry(0.3, 0.2, 16, 32),
            this.torusMaterial
        )
        this.torus.position.x = 1.5

        // Clearcoat
        this.torusMaterial.clearcoat = 1
        this.torusMaterial.clearcoatRoughness = 1

        this.torusMaterial.sheen = 1
        this.torusMaterial.sheenRoughness = 0.25

        this.torusMaterial.iridescence = 1
        this.torusMaterial.iridescenceIOR = 1
        this.torusMaterial.iridescenceThicknessRange = [ 100, 800 ]

        this.torusMaterial.transmission = 1
        this.torusMaterial.ior = 1.1
        this.torusMaterial.thickness = 0.5

        scene.add(this.torus)

        /**
         * Lights
         */
        this.ambientLight = new THREE.AmbientLight(0xffffff, 1)
        scene.add(this.ambientLight)

        this.pointLight = new THREE.PointLight("green", 3)
        this.pointLight.position.x = 2
        this.pointLight.position.y = 3
        this.pointLight.position.z = 4
        scene.add(this.pointLight)
    }

    destroy(scene)
    {

        scene.remove(this.torus)
        this.torus.dispose()
        this.torusMaterial.dispose()
        scene.remove(this.sphere)
        this.sphere.dispose()
        this.sphereMaterial.dispose()
        scene.remove(this.plain)
        this.textureColor.dispose()
        this.textureAO.dispose()
        this.textureHeight.dispose()
        this.textureMetallic.dispose()
        this.textureNormal.dispose()
        this.textureOpacity.dispose()
        this.textureRoughness.dispose()
        scene.remove(this.ambientLight)
        this.ambientLight.dispose()
        scene.remove(this.pointLight)
        this.pointLight.dispose()
        this.material.dispose()
    }
}