import * as THREE from 'three'

export default class TexturedCube
{
    constructor (scene)
    {
        //Create a loading manager so we can catch errors
        this.loadingManager = new THREE.LoadingManager()
        this.loadingManager.onError = () =>
        {
            console.log('loading error')
        }

        //Load the texture
        this.textureLoader = new THREE.TextureLoader(this.loadingManager)
        this.textureColor = this.textureLoader.load("Textures/Door/Door_Wood_001_basecolor.jpg")
        this.textureColor.colorSpace = THREE.SRGBColorSpace

        //We can make transformations to the texture
        this.textureColor.repeat.x = 2
        this.textureColor.repeat.y = 3
        this.textureColor.wrapS = THREE.RepeatWrapping
        this.textureColor.wrapT = THREE.RepeatWrapping

        //We can adjust the mipmapping
        this.textureColor.generateMipmaps = false
        this.textureColor.minFilter = THREE.NearestFilter

        //Create the cube and add it to the scene
        this.cube = new THREE.Mesh(
            new THREE.BoxGeometry(1,1,1),
            new THREE.MeshBasicMaterial({ map: this.textureColor })
        )
        scene.add(this.cube)
    }

    destroy(scene)
    {
        scene.remove(this.cube)
        this.textureColor.dispose()
    }
}