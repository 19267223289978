import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import SimpleShapes from "./SimpleShapes.js"
import TexturedCube from "./TexturedCube.js"
import MaterialPlain from "./MaterialPlain.js"
import * as CustomDraw from "./CustomDraw.js"
import GUI from "lil-gui"

//Debug
const debugGUI = new GUI( {width: 300, title: 'Debug'})
debugGUI.close()
debugGUI.hide()

const debugData =
{
    sceneNumber: 3,
    showAxisHelpers: false,
}

const sceneObjectsFolder = debugGUI.addFolder("Scene Options")
sceneObjectsFolder.add( debugData, 'sceneNumber', {
    SimpleShapes: 0, 
    VertexTriangle: 1,
    TexturedCube: 2,
    MaterialPlain: 3  
}  ).onFinishChange(BuildScene);
sceneObjectsFolder.close()

const generalFolder = debugGUI.addFolder("General")
const axisHelper = new THREE.AxesHelper(1.5)
generalFolder.add(debugData, "showAxisHelpers").onChange(OnChangeAxisHelper)
generalFolder.close()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Sizes
const windowSize = { width: window.innerWidth, height: window.innerHeight }
window.addEventListener('resize', OnWindowResize)

// Camera
const camera = new THREE.PerspectiveCamera(75, windowSize.width / windowSize.height)
camera.position.z = 3

//Controls
window.addEventListener("dblclick", OnDoubleClick)
window.addEventListener("keydown", OnKeyDown)

const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

//Scene
let scene
let simpleShapes
let texturedCube
let materialPlain
BuildScene()

// Renderer
const renderer = new THREE.WebGLRenderer({ canvas: canvas })
OnWindowResize() //We do this so we dont miss anything when setting up the size

//Set up an update function
let time = Date.now()

//Start update
window.requestAnimationFrame(Update)

function OnDoubleClick()
{
    //Some browsers are not up to date and dont use document.fullscreenElement
    //so we have to account for the fact they may not use it.

    const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement

    if(!fullscreenElement)
    {
        if(canvas.requestFullscreen)
        {
            canvas.requestFullscreen()
        }
        else if(canvas.webkitRequestFullscreen)
        {
            canvas.webkitRequestFullscreen()
        }
    }
    else
    {
        if(document.exitFullscreen)
        {
            document.exitFullscreen()
        }
        else if(document.webkitExitFullscreen)
        {
            document.webkitExitFullscreen()
        }
    }
}

function OnChangeAxisHelper()
{
    if(debugData.showAxisHelpers)
    {
        scene.add(axisHelper)
    }
    else
    {
        scene.remove(axisHelper)
    }
}

function BuildScene()
{
    scene = new THREE.Scene()
    scene.add(camera)
    if(debugData.axisHelper)
    {
        scene.add(axisHelper)
    }

    //Create objects
    simpleShapes = null
    if(texturedCube)
    {
        texturedCube.destroy(scene)
        texturedCube = null;
    }
    if(materialPlain)
    {
        materialPlain.destroy(scene)
        materialPlain = null
    }
    switch(debugData.sceneNumber)
    {
        case 0:
            simpleShapes = new SimpleShapes(scene)
            break
        case 1:
            simpleShapes = null
            CustomDraw.DrawSimpleTriangle(scene)
            break
        case 2:
            texturedCube = new TexturedCube(scene)
            break
        case 3:
            materialPlain = new MaterialPlain(scene)
            break
    }
}

function OnKeyDown(event)
{
    if(event.key == 'd')
        debugGUI.show(debugGUI._hidden)
}

function OnWindowResize()
{
     windowSize.width = window.innerWidth
     windowSize.height = window.innerHeight
     camera.aspect = windowSize.width / windowSize.height
     camera.updateProjectionMatrix()
     renderer.setSize(windowSize.width, windowSize.height)
     renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
}

function Update()
{
    //Work out deltatime
    const currentTime = Date.now()
    const deltaTime = (currentTime - time) / 1000
    time = currentTime;

    if(debugData.sceneNumber == 0)
    {
        simpleShapes.Update(deltaTime);
    }

    //Update controls
    controls.update()

    //Update render
    renderer.render(scene, camera)

    //Queue next update
    window.requestAnimationFrame(Update)
}