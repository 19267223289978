import * as THREE from 'three'
import gsap from 'gsap'

export default class SimpleShapes
{
    constructor(scene)
    {
        //Create a group 
        this.group = new THREE.Group()
        scene.add(this.group);
        
        //We may need to directly set the rotation order
        this.group.rotation.reorder("YXZ")
        
        // Object
        this.geometry = new THREE.BoxGeometry(1, 1, 1)
        this.material = new THREE.MeshBasicMaterial({ color: 0xff0000 })
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.group.add(this.mesh)
        
        //Simple mesh
        this.cube = new THREE.Mesh(
            new THREE.BoxGeometry(1,1,1),
            new THREE.MeshBasicMaterial({ color: 0xff0000 })
        )
        this.cube.position.x = 1.5
        this.group.add(this.cube)
        
        //Move group
        this.group.position.x = -0.75

        //Animation using gsap withou the need for an update
        gsap.to(this.mesh.position, { duration: 1, delay: 1, z: -2 })
        gsap.to(this.mesh.position, { duration: 1, delay: 2, z: 0 })
    }
    
    Update(deltaTime)
    {
        //Add some rotation
        this.cube.rotation.y += deltaTime * 1
    }
}

